<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section">

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{alert_msg_descricao}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog 
      v-model="dialogEdt"
      persistent
      max-width="600px" 
    >
    
      <v-card>
         <v-toolbar color="success" dark  style="font-size:18px">  Editar Ticket</v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" > 

            <v-row>
 
       

                <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.codigo" label="Código do Cartão*" :rules="[v => !!v || 'Campo Obrigatório']" dense   outlined ></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.n_inscricao" label="Nº da Inscrição (Contrato)*" :rules="[v => !!v || 'Campo Obrigatório']" dense   outlined ></v-text-field>
              </v-col>


               <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field-money  v-model="card.valor_correcao" label="Valor Inicial*" :rules="[v => !!v || 'Campo Obrigatório']"   v-bind:properties="{ prefix: 'R$', outlined: true,  dense: true}" > </v-text-field-money>
              </v-col>

              
              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                <v-text-field v-model="card.nome_falecido" label="Nome do Falecido*"  :rules="[v => !!v || 'Campo Obrigatório']" dense outlined  ></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                <v-text-field value="" v-model="card.nome_responsavel" label="Responsável" :rules="[v => !!v || 'Campo Obrigatório']" dense outlined  ></v-text-field>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.email_responsavel" label="Email Responsável" dense outlined ></v-text-field>
              </v-col>

               <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.telefone_responsavel"  v-mask="'(##)#####-####'" label="Telefone Responsável*" :rules="[v => !!v || 'Campo Obrigatório']"  dense outlined></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                  <v-select v-model="card.n_capela"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="capelas" label="Capela*" outlined dense></v-select>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                  <v-text-field v-model="card.inicio"  v-mask="'##/##/#### ##:##'" label="Validade Inicio*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                  <v-text-field v-model="card.fim"  v-mask="'##/##/#### ##:##'" label="Validade Fim*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined dense></v-text-field>
              </v-col> 


            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
                  <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="success"  text
            @click="dialogEdt = false">
            Fechar
          </v-btn>

          <v-btn :loading="showload"
            color="success"
                        @click="enviar_update">

           <v-icon right dark>mdi-check</v-icon>
            Confirmar
          </v-btn>

        </v-card-actions>
        <br/>
      </v-card>
      
    </v-dialog>


<v-dialog 
      v-model="dialogAdd"
      persistent
      max-width="600px" 
    >
    
      <v-card>
         <v-toolbar color="success" dark  style="font-size:18px"> Criar Novo Ticket</v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form_card" > 

            <v-row>
 
       

              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                  <v-select v-model="card.codigo"  :rules="[v => !!v || 'Campo Obrigatório']"  dense :items="codigos"  label="Código do Cartão*" outlined></v-select>
              </v-col> 


              <v-col class="d-flex"  style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.n_inscricao" label="Nº da Inscrição (Contrato)*" :rules="[v => !!v || 'Campo Obrigatório']"  dense outlined ></v-text-field>
              </v-col>

               <v-col class="d-flex" style="height:60px" cols="6" sm="6">
              
                <v-text-field-money  v-model="card.valor" label="Valor Inicial*" :rules="[v => !!v || 'Campo Obrigatório']"   v-bind:properties="{ prefix: 'R$', outlined: true,  dense: true}" > </v-text-field-money>
              </v-col>

              
              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                <v-text-field v-model="card.nome_falecido" label="Nome do Falecido*"  :rules="[v => !!v || 'Campo Obrigatório']" dense outlined  ></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                <v-text-field value="" v-model="card.nome_responsavel" label="Responsável" :rules="[v => !!v || 'Campo Obrigatório']" dense outlined  ></v-text-field>
              </v-col> 


              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.email_responsavel" label="Email Responsável" dense  outlined ></v-text-field>
              </v-col>

               <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                <v-text-field v-model="card.telefone_responsavel"  v-mask="'(##)#####-####'" dense label="Telefone Responsável*" :rules="[v => !!v || 'Campo Obrigatório']"   outlined></v-text-field>
              </v-col>

              <v-col class="d-flex" style="height:60px" cols="12" sm="12">
                  <v-select v-model="card.n_capela"  :rules="[v => !!v || 'Campo Obrigatório']" dense :items="capelas" label="Capela*" outlined></v-select>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                  <v-text-field v-model="card.inicio"  v-mask="'##/##/#### ##:##'" label="Validade Inicio*" dense :rules="[v => !!v || 'Campo Obrigatório']"   outlined></v-text-field>
              </v-col> 

              <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                  <v-text-field v-model="card.fim"  v-mask="'##/##/#### ##:##'" label="Validade Fim*" dense :rules="[v => !!v || 'Campo Obrigatório']"   outlined></v-text-field>
              </v-col> 


            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
                  <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="success"  text
            @click="dialogAdd = false">
            Fechar
          </v-btn>

          <v-btn :loading="showload"
            color="success"
                        @click="enviar">

           <v-icon right dark>mdi-check</v-icon>
            Confirmar
          </v-btn>

        </v-card-actions>
        <br/>
      </v-card>
      
    </v-dialog>
    



<div class="card">
  <div class="card-body">
    <h4 class="card-title"></h4>
    <base-material-card
      icon="mdi-ticket"
      title="Ticket Cadastrados"
      class="px-5 py-3"
    >



      <v-row  class="px-2 py-6" >
        <v-col class="d-flex" cols="9" sm="9">
            <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                
                <v-dialog
                    ref="dialogi"
                    v-model="date_modal_p_i"
                    :return-value.sync="filtro.dataI"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense
                        v-model="filtro.dataI"
                        label="Período - Início (Data de Criação)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtro.dataI"
                      scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="date_modal_p_i = false">
                        Fechar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogi.save(currentDateTime(filtro.dataI))">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

              </v-col>

            <v-col class="d-flex" style="height:60px" cols="6" sm="6">
                
                <v-dialog
                    ref="dialog"
                    v-model="date_modal_p_f"
                    :return-value.sync="filtro.dataF"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined dense
                        v-model="filtro.dataF"
                        label="Período - Fim (Data de Criação)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filtro.dataF"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="date_modal_p_f = false"
                      >
                        Fechar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(currentDateTime(filtro.dataF))"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

              </v-col>
         </v-col> 

      <v-col class="d-flex" cols="3" sm="3">
          <v-btn  color="success text-white" style="margin-top:20px;" small  @click="filtrar()">Filtrar
            <v-icon
              right
              dark>
              mdi-filter
            </v-icon>
          </v-btn>
      </v-col> 

    </v-row>



      <v-data-table :single-expand="true" :expanded.sync="expanded" show-expand :headers="fields" :items="cards" item-key="codigo" class="elevation-0" :loading="loading">
        
         
         <template v-slot:item.ativo="{ item }">

            <v-chip
              :color="getBadge(item.ativo)" style="width:60px;text-align:center;"
              dark>
              {{getStatus(item.ativo)}}                    
            </v-chip>
   

            <v-btn :loading="btn_status" small :color="getActionColor(item.ativo)" style="margin-left:16px;width:110px;" dense  @click="mudarStatus(cards.indexOf(item), item)">{{getActionText(item.ativo)}}
            </v-btn>

             <v-btn  small color="blue" style="margin-left:16px;width:80px;" dense  @click="editar(item)"> Editar
            </v-btn>

        </template>

        


       

        <template v-slot:expanded-item="{ headers, item }">
        <td>
          Início <br/> {{ item.inicio }}
        </td>
        <td>
        <span>Valor Inicial <br/> R$ {{ formatPrice(item.valor_inicial) }}</span>
        </td>
        <td>
        <span>Saldo <br/> R$ {{ formatPrice(item.valor) }}</span>
        </td>
         <td>
          Responsável <br/> {{ item.nome_responsavel }}
        </td>
        <td>
          E-mail <br/> {{ item.email_responsavel }}
        </td>
        <td>
          Telefone <br/> {{ item.telefone_responsavel }}
        </td>
         <td>
          Falecido <br/> {{ item.nome_falecido }}
        </td>
        
      </template>





      </v-data-table>

    </base-material-card>
      <br/>
     <v-row
      > 
        <v-btn  color="success text-white"   @click="novo()">NOVO TICKET
          <v-icon
            right
            dark
          >
            mdi-ticket
          </v-icon>

        </v-btn>

    </v-row>

  </div>
</div>
   
  </v-container>
</template>
<script>
  import Cartao from '../../../services/cartao'
  import moment from 'moment'
  import VueBarcode from 'vue-barcode';
    var pdfMake = require('pdfmake/build/pdfmake.js');
    var pdfFonts = require('pdfmake/build/vfs_fonts.js');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    components: {
        'barcode': VueBarcode
    },

    data: () => ({
      periodo_selecionado: 'Disponível',
      show: true,
      btn_status: false,
      alert_msg:false,
      alert_msg_descricao: '',
      showload: false,
      date_modal_p_i: false,
      date_modal_p_f: false,
      date_modal_i: false,
      date_modal_f: false,
      loading: false,
      
      card:{
          n_inscricao: '0',
          valor_correcao: 0,
          nome_falecido: '',
          nome_responsavel: '',
          email_responsavel: '',
          telefone_responsavel: '',
          n_capela: '',
          codigo: '',
          valor: '60',
          inicio: '',
          fim: '',
      },

      capelas: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
      ],

       codigos: [
        
      ],



      

      periodos: [
        'Disponível',
        'Usado'
      ],

      "Liberar Carência Dependente": {
        field: "sem_carencia_dependene",
        callback: (value) => {
        switch (value) {
          case true: return 'Sim'
          case false: return 'Não'
          default: ''
        }
        },
      },

       "Validade": {
        field: "validade",
        callback: (value) => {
          return  moment(value).format('DD/MM/YYYY')
        },
      },


    select_status: true,
       fields:[
        
        { text: 'Nº Ticket', sorter: true, filter: false, value:'id', class: 'title'},  
        { text: 'Código do Cartão', sorter: true, filter: false, value:'codigo', class: 'title'},
        { text: 'Inscrição', sorter: false, filter: false, value:'n_inscricao', class:'title'},
        { text: 'Criado por', sorter: true, filter: false, value:'userCreate', class: 'title'},
        { text: 'Criado em', sorter: false, filter: false, value:'dataCreate', class:'title'},
                { text: 'Validade Final', sorter: false, filter: false, value:'fim', class:'title'},
        { text: 'Situação', sorter: true, filter: false, value:'ativo', class:'title'},

      ],
      cards: [],
      filtro:{
          dataI: '',
          dataF: '',
      },

     
     

      rules: [
        value => !!value || 'Required.',
      ],
      dialogAdd: false,
            dialogEdt: false,

      msg_dialog: '',

    }),

    mounted () {
      this.listar(this.periodo_selecionado)
      this.listar_codigos();
    },

    methods: {

      createPDF() {
        var docDefinition = {
        content: [
            {
                table: {
                    headerRows: 1,
                    widths: [ '*', 'auto', 100, '*' ],

                    body: []
                }
            }
        ]
        };
        docDefinition.content[0].table.body.push(this.columns);
        for(var i=0;i<this.checkedRows.length;i++){
            docDefinition.content[0].table.body.push(Object.values(this.checkedRows[i]));  
        }
        pdfMake.createPdf(docDefinition).download('PO.pdf');
      },


      onSubmit(event) {
         alert('subm')
      },

      onReset(event) {
        this.lote.qtd = 1
        this.lote.desconto_adesao = 0
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },

      editar(item){
        this.dialogEdt = true
        this.card = item
        this.card.valor_correcao = item.valor_inicial

      },

      novo(){
        this.card = {
          n_inscricao: '0',
          valor_correcao: 0,
          nome_falecido: '',
          nome_responsavel: '',
          email_responsavel: '',
          telefone_responsavel: '',
          n_capela: '',
          codigo: '',
          valor: '60',
          inicio: '',
          fim: '',
        };
        this.dialogAdd = true;

      },
      filtrar(){

          if(this.filtro.dataI != '' && this.filtro.dataF != ''){
            this.loading = true
            Cartao.lista_cartao_periodo(this.filtro).then(response => {
                  this.cards = response.data
              }).catch(e => {
                  if(e.response.status === 401){
                  }
              }).finally(() => {
                  this.loading = false
              })

          }else{
              this.alert_msg = true;
              this.alert_msg_descricao = 'Campo Data Inicial e Final Obrigatório'

          }


      },

      listar_codigos () {
        Cartao.listacodigos("ativo").then(response => {
                    this.codigos = response.data
                }).catch(e => {
                  
                }).finally(() => {
                    this.loading = false
                })        
            
      },
      listar () {
          this.loading = true

        Cartao.lista().then(response => {
            this.cards = response.data
        }).catch(e => {
            if(e.response.status === 401){;

            }

        }).finally(() => {
             this.loading = false

        })
      },

      onChange(event) {
        var value = event
        if(value == true){
          value = 'Disponível'
        }
        if(value == false){
          value = 'Usado'
        }

        this.listar(value)
      },
     
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
      },

    enviar_update() {
        if(this.$refs.form_card.validate()){
        this.showload = true,
        Cartao.update_cartao(this.card).then(response => {
                          this.cards = response.data
                              if(response.status === 200){
                                  this.alert_msg_descricao = 'Atualizado com sucesso!'
                              }
                        }).catch(e => {
                              this.showload = false;
                              this.dialogEdt = false;
                              this.alert_msg = true;
                              this.alert_msg_descricao = e.response.data

                          if(e.response.status === 403){
                              this.alert_msg_descricao = "Usuário não tem permissão"
                          }
                          
                        }).finally(() => {
                              this.dialogEdt = false;
                              this.showload = false;
                              this.$refs.form_card.reset();
                              this.alert_msg = true;
                              this.listar();

                              
            })
        }       
      },

      enviar() {
        if(this.$refs.form_card.validate()){
        this.showload = true,
        Cartao.criarCartao(this.card).then(response => {
                          this.cards = response.data
                              if(response.status === 200){
                                this.alert_msg_descricao = 'Criado com sucesso!'
                              }
                          

                        }).catch(e => {
                              this.showload = false;
                              this.alert_msg = true;
                              this.dialogAdd = false;
                              this.alert_msg_descricao = e.response.data
                         if(e.response.status === 403){
                              this.alert_msg_descricao = "Usuário não tem permissão"
                          }
                          
                        }).finally(() => {
                              this.dialogAdd = false;
                              this.showload = false;
                              this.$refs.form_card.reset();
                              this.alert_msg = true;
                              
            })
        }       
      },

     


      mudarStatus(pos, item){
          this.btn_status = true,
          Cartao.mudarStatus(item).then(response => {
          this.alert_msg = true;
          this.alert_msg_descricao = response.data.mensagem;

          let aux = response.data;
          Object.assign(this.cards[pos], aux)

         // let aux = response.data;
          //this.cards.$set(index, item)
          
          // this.cards.splice(item, 1)
          // this.cards.indexOf(item).
          // item.ativo = response.data;
          // Object.assign(this.cards[pos], item)
         // this.cards.push(item)

         //this.cards.splice(item, pos, aux)


        }).catch(e => {
          this.btn_status = false;
          this.alert_msg = true;
          this.alert_msg_descricao = e.response.data
          if(e.response.status === 403){
                              this.alert_msg_descricao = "Usuário não tem permissão"
             }

        }).finally(() => {
          this.btn_status = false;

        })
      },

      setDisabled: function(status){
        return status
      },

      setEnable: function(status){
        return !status
      },

     

      toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },

    currentDateTime(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    
    parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

    getBadge (status) {
      switch (status) {
         case true: return 'green lighten-1'
        case false: return 'red lighten-1'
        default: 'success'
      }
      },

      getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },
     formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

      getActionText(status) {
      switch (status) {
        case true: return 'Desabilitar'
        case false: return 'Habilitar'
        default: ''
      }
    },

    getActionColor(status) {
      switch (status) {
        case true: return 'red lighten-1'
        case false: return 'green lighten-1'
        default: ''
      }
    },

    

    }
  }

</script>
